<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Pergunta
            </b-input-group-prepend>
            <b-form-input
              id="question-input"
              v-model="question"
              :state="questionRules"
              :readonly="wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="questionRules">
            A pergunta não pode estar vazio.
          </b-form-invalid-feedback>
        </b-form>

        <br/>

        <div style="column-count: 2;">
          <b-form  @submit.stop.prevent>
            <b-input-group>
              <b-input-group-prepend is-text>
                Resposta
              </b-input-group-prepend>
              <b-form-input
                id="answer1-input"
                v-model="answer1"
                :state="answer1Rules"
                :readonly="wfPublished"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="answer1Rules">
              A resposta não pode estar vazio.
            </b-form-invalid-feedback>
          </b-form>
          <b-form-invalid-feedback :state="answer1Rules" />
          <b-form-select 
            v-model="selecNodeAnswer1" 
            @change="changeAnswer1(selecNodeAnswer1)" 
            :options="conectedNodes"
            :disabled="wfPublished"
          >
          </b-form-select>
        </div>

        <br/>

        <div style="column-count: 2;">
          <b-form  @submit.stop.prevent>
            <b-input-group>
              <b-input-group-prepend is-text>
                resposta
              </b-input-group-prepend>
              <b-form-input
                id="answer2-input"
                v-model="answer2"
                :state="answer2Rules"
                :readonly="wfPublished"
              ></b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="answer2Rules">
              A resposta não pode estar vazio.
            </b-form-invalid-feedback>
          </b-form>
          <b-form-invalid-feedback :state="answer2Rules" />
          <b-form-select 
            v-model="selecNodeAnswer2" 
            @change="changeAnswer2(selecNodeAnswer2)" 
            :options="conectedNodes"
            :disabled="wfPublished"
          ></b-form-select>
        </div>

        <br/>

        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Sequência do Kanban
            </b-input-group-prepend>
            <b-form-input
              id="sequence-input"
              v-model="sequence"
              :state="sequenceRules"
              type="number"
              :readonly="entryGateway || wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="sequenceRules">
            A sequência do node não pode estar vazio e deve ser maior ou igual 1.
          </b-form-invalid-feedback>
        </b-form>
        
        <br/>
      </template>

      <template #modal-footer>
        <b-button
          v-if="!wfPublished"
          size="sm" 
          variant="success"
          @click="saveChanges()"
        >
          Salvar
        </b-button>

        <div
          v-else
        >
          <span
            style="font-size: small; color: grey;"
          >
            Edição bloqueada, devido ao estado atual do workflow ser
          </span>
          <span
            style="font-size: small; font-weight: 800; color: grey;"
          >
            Publicado.
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'dialogCondition',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
      selecNodeAnswer1: null,
      selecNodeAnswer2: null,
      question: '',
      answer1: '',
      answer2: '',
      nodeInfo: null,
      actionData: null,
      sequence: '0',
    };
  },

  async mounted(){
    let success = await this.setNodeInfo()
    if (!success){
      this.closeDialog()
    }
  },

  computed: {
    authInfo: () => store.getters.authInfo,

    wfPublished: () => store.state.isPublished,

    intranetUrl: () => store.getters.intranetUrl,

    conectedNodes: function() {
      let result = []
      if (this.node){
        let outgoingConectors = this.node.definition.outgoing
        if (outgoingConectors.length > 0){
          outgoingConectors.forEach((conector, index) => {
            result.push({
              'value': conector.targetRef.$attrs.backId,
              'text': conector.targetRef.name,
              'index': index,
            })
          })
        }
      }
      return result
    },

    sequenceRules() {
      return Number(this.sequence) > -1
    },

    questionRules() {
      return this.question ? this.question.length > 0 : false
    },

    answer1Rules() {
      return this.answer1 ? this.answer1.length > 0 : false
    },

    answer2Rules() {
      return this.answer2 ? this.answer2.length > 0 : false
    },

    rules(){
      return [
        this.answer1Rules,
        this.answer2Rules,
        this.questionRules,
        this.sequenceRules,
      ]
    },

    entryGateway(){
      let node = this.node.definition.incoming ? this.node.definition.incoming[0].sourceRef : false
      if (node && node.$type === 'bpmn:ExclusiveGateway'){
        return true
      } 
      return false
    }
  },

  watch: {

   open: function(){
     if (this.open){
       this.setNodeInfo()
     }
   }
  },
  
  methods: {
    async saveChanges(){
      if (this.checkFormValidity() === false) {
        return
      }
      if (this.actionData){
        await this.putActCondition()
      } else {
        await this.postActCondition()
      }
      await this.putNode()
      this.updateNode()
      this.$emit("saveChanges")
      this.$emit("close")
    },

    updateNode(){
      let title = rules.concatString(`(${this.sequence})\n ${this.question}`)
      this.node.definition.name = title
    },

    changeAnswer1(selecNode){
      let conector = this.conectedNodes.find(el => el.value === selecNode)
      if (conector){
        this.selecNodeAnswer2 = conector.index === 0 ? this.conectedNodes[1].value : this.conectedNodes[0].value
      }
    },

    changeAnswer2(selecNode){
      let conector = this.conectedNodes.find(el => el.value === selecNode)
      if (conector){
        this.selecNodeAnswer1 = conector.index === 0 ? this.conectedNodes[1].value : this.conectedNodes[0].value
      }
    },

    async setNodeInfo(){
      let result = true
      let nodeData = await store.dispatch('getNode', {
        fields: "['id', 'title', 'sequence', 'res_model', 'res_id']",
        domain: "('id', '=', " + this.node.definition.$attrs.backId + ")",
        token: this.authInfo.token,
      })
      if (nodeData){
        if (nodeData[0].res_id){
          let result = await store.dispatch('getActCondition', {
            fields: "['id', 'question', 'answer1', 'answer2', 'node1', 'node2']",
            domain: "('id', '=', " + nodeData[0].res_id + ")",
            token: this.authInfo.token,
          })
          this.actionData = result ? result[0] : null
        }
        this.nodeInfo = nodeData[0]
        this.fillfields()
      } else {
        result = false
      }
      return result
    },

    fillfields(){
      if (this.actionData){
        this.question = this.actionData.question
        this.answer1 = this.actionData.answer1
        this.answer2 = this.actionData.answer2
        this.selecNodeAnswer1 = this.actionData.node1[0]
        this.selecNodeAnswer2 = this.actionData.node2[0]
        this.sequence = this.nodeInfo.sequence
      } else {
        this.selecNodeAnswer1 = this.conectedNodes[0].value
        this.selecNodeAnswer2 = this.conectedNodes[1].value
      }
    },

    async postActCondition(){
      let data = {
        question: this.question,
        answer1: this.answer1,
        answer2: this.answer2,
        node1: this.selecNodeAnswer1,
        node2: this.selecNodeAnswer2,
      }
      let newActCond = await store.dispatch('postNewActionCondition', {
        data: data,
        token: this.authInfo.token,
      })
      if (newActCond){
        data['id'] = newActCond.id
        this.actionData = data
      }
    },

    async putActCondition(){
      let result = await store.dispatch('putActCondition', {
        payload: {
          question: this.question,
          answer1: this.answer1,
          answer2: this.answer2,
          node1: this.selecNodeAnswer1,
          node2: this.selecNodeAnswer2,
        },
        id: this.actionData.id,
        token: this.authInfo.token,
      })
      if (result) return true
      return false
    },

    async putNode(){
      await store.dispatch('putNode', {
        payload: {
          title: this.question,
          res_model: 'intranet.wf.act.cond',
          res_id: this.actionData.id,
          sequence: this.sequence,
        },
        id: this.node.definition.$attrs.backId,
        token: this.authInfo.token,
      })
    },
    
    closeDialog(){
      this.$emit("close")
    },
    
    checkFormValidity() {
      return this.rules.find(el => el === false)
    },
  },
};
</script>
<style scoped>
.createBtn{
  width: 80px;
  margin-left: 20px;
  background-color: #0092ff;
}
</style>
