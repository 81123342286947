<template>
  <crown-button
    v-if="wfPublished ? isAnnotation : true"
    :title="$t('Deletar')"
    role="menuitem"
    id="delete-button"
    aria-label="Delete this node"
    @click="isPoolLane ? removePoolLaneShape() : removeShape()"
    v-b-tooltip.hover.viewport.d50="{ customClass: 'no-pointer-events' }"
  >
    <img
      :src="trashIcon"
      aria-hidden="true"
      data-prefix="fas"
      data-icon="trash-alt"
      class="fa-trash-alt trash-icon"
      alt=""
    >
  </crown-button>
</template>

<script>
import trashIcon from '@/assets/trash-alt-solid.svg';
import CrownButton from '@/components/crown/crownButtons/crownButton';
import { removeFlows } from '@/components/crown/utils.js';
import store from '@/store';

export default {
  components: { CrownButton },
  props: { graph: Object, shape: Object, node: Object },
  data() {
    return {
      teste: false,
      trashIcon,
      flowsTypes: [
        'processmaker-modeler-sequence-flow',
        'processmaker-modeler-association',
      ]
    };
  },

  computed: {
    isPoolLane() {
      return this.node.type === 'processmaker-modeler-lane';
    },

    isAnnotation() {
      return this.node.type === 'processmaker-modeler-text-annotation'
    },

    wfPublished: () => store.state.isPublished,

    authInfo: () => store.getters.authInfo,
    inProcess: () => store.state.inProcess
  },

  methods: {
    removeFlows,
    async removeShape() {
      // Permiti apenas remove o node, se não tiver outra ação em andamento
      if (!this.inProcess){
        store.commit("SET_IN_PROCESS", { value: true })
        let delResult = null

        if ("backId" in this.node.definition.$attrs){
          delResult = await this.delNodeOrConectorInBackEnd()
          if (delResult){
            this.removeFlows(this.graph, this.shape);
            this.$emit('remove-node', this.node);
            
          } else {
            store.commit("SET_IN_PROCESS", { value: false })
          }
        } else {
          this.removeFlows(this.graph, this.shape);
          this.$emit('remove-node', this.node);
        }
      } else {
        this.$emit("showInProcessAlert")
      }
    },
    removePoolLaneShape() {
      this.$emit('remove-node', this.node);

      const poolComponent = this.node.pool.component;
      const sortedLanes = poolComponent.sortedLanes();

      if (sortedLanes.length === 2) {
        /* Do not allow pool with only one lane;
         * if removing 2nd last lane, remove the other lane as well */
        this.$emit('remove-node', sortedLanes.filter(lane => lane !== this.shape)[0].component.node);
        return;
      }

      if (this.shape === sortedLanes[sortedLanes.length - 1]) {
        poolComponent.fillLanes(this.shape, 'top-right', true);
        return;
      }

      poolComponent.fillLanes(this.shape, 'bottom-right', true);
    },

    //////////.:Ilios Sistemas:.//////////
    async delNodeOrConectorInBackEnd(){
      if (this.flowsTypes.includes(this.node.type)){
        if (this.node.definition.sourceRef.$attrs.implementation === 'processmaker-modeler-condition'){
          let condition = await store.dispatch('getNode', {
            fields: "['res_id']",
            domain: "('id', '=', " + this.node.definition.sourceRef.$attrs.backId + ")",
            token: this.authInfo.token,
          })
          if (condition){
            await store.dispatch('putActCondition', {
              id: condition[0].res_id,
              payload: {
                node1: null,
                node2: null,
              },
              token: this.authInfo.token,
            })
          }
        }
        return await store.dispatch('deleteConector',{
          conectorId: this.node.definition.$attrs.backId,
          token: this.authInfo.token
        })
      } else {
        return await store.dispatch('deleteNode',{
          nodeId: this.node.definition.$attrs.backId,
          token: this.authInfo.token
        })
      }
    }
  },
};
</script>
<style>
  .trash-icon {
    height: 13.25px;
  }
</style>
