import Gateway from '@/components/nodes/inclusiveGateway/inclusiveGateway';
import idConfigSettings from '@/components/inspectors/idConfigSettings';
import rules from '@/mixins/ilios_functions.js'

window.ProcessMaker.EventBus.$on('modeler-init', ({ registerNode }) => {
  /* Add a custom node example */

  const implementation = 'processmaker-modeler-condition';
  const nodeId = 'processmaker-modeler-condition';

  const component = {
    extends: Gateway,
    watch: {
      'node.definition.name'(name) {
        switch (name) {
          default:
            this.$delete(this.markers.topRight, 'vocabulary');
            this.$delete(this.markers.topRight, 'clock');
        }
      },
    },
  };

  const nodeType = {
    id: nodeId,
    component,
    bpmnType: 'bpmn:InclusiveGateway',
    control: true,
    category: 'Other',
    icon: require('@/assets/toolpanel/condition.svg'),
    label: 'Condição',
    rank: 59,
    definition(moddle) {
      return moddle.create('bpmn:InclusiveGateway', {
        name: 'Condição',
        implementation,
        config: JSON.stringify({}),
        backId: null,
      });
    },
    diagram(moddle) {
      return moddle.create('bpmndi:BPMNShape', {
        bounds: moddle.create('dc:Bounds', {
          height: 36,
          width: 36,
        }),
      });
    },
    inspectorConfig: [
      {
        name: 'Send Tweet',
        items: [
          {
            component: 'FormText',
            config: {
              label: 'Send Tweet',
              fontSize: '2em',
            },
          },
          {
            component: 'FormInput',
            config: idConfigSettings,
          },
          {
            component: 'FormInput',
            config: {
              label: 'Name',
              helper: 'Name',
              name: 'name',
            },
          },
        ],
      },
    ],
    entryRules(sequenceFlow){
      let result = []
      let findError
      result.push(rules.oneEntryConector(sequenceFlow))
      findError = result.find(el => el.result === false)
      if (findError){
        return findError
      } else {
        return {result: true}
      }
    },
    exitRules(genericFlow){
      let result = []
      let findError
      result.push(rules.twoExitConector(genericFlow))
      result.push(rules.entryNotIsMultGateway(genericFlow))
      findError = result.find(el => el.result === false)
      if (findError){
        return findError
      } else {
        return {result: true}
      }
    },
  };

  registerNode(nodeType, definition => {
    if (definition.get('implementation') === implementation) {
      return nodeId;
    }
  });
});
