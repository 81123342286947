<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Título
            </b-input-group-prepend>
            <b-form-input
              id="title-input"
              v-model="title"
              :state="titleRules"
              :readonly="wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="titleRules">
            O título não pode estar vazio.
          </b-form-invalid-feedback>
        </b-form>

        <br/>

        <b-form  @submit.stop.prevent>
           <b-input-group>
            <b-input-group-prepend is-text>
              Modelo do email
            </b-input-group-prepend>
            <b-form-input 
              list="email-list-id"
              v-model="selectedEmail"
              :state="emailRules"
              :readonly="wfPublished"
              @focus="updateEmailList()"
            ></b-form-input>
            <b-button 
              class="createBtn" 
              size="sm"
              :disabled="wfPublished"
              @click="openNewActionLink()"
            >
              Criar
            </b-button>
            <datalist id="email-list-id">
              <option v-for="email in emailList" :key="email.id">{{ email.subject ? email.subject : '' }}</option>
            </datalist>
          </b-input-group>
          <b-form-invalid-feedback 
            :state="emailRules"
          >
            Selecione um modelo do email.
          </b-form-invalid-feedback>
        </b-form>
      </template>

      <template #modal-footer>
        <b-button
          v-if="!wfPublished"
          size="sm" 
          variant="success" 
          @click="saveChanges()"
        >
          Salvar
        </b-button>

        <div
          v-else
        >
          <span
            style="font-size: small; color: grey;"
          >
            Edição bloqueada, devido ao estado atual do workflow ser
          </span>
          <span
            style="font-size: small; font-weight: 800; color: grey;"
          >
            Publicado.
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'dialogSendEmail',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
      selectedEmail: null,
      title: '',
      nodeInfo: null,
      emailList: [],
    };
  },

  async mounted(){
    await this.setNodeInfo()
    this.updateEmailList()
  },

  computed: {
    authInfo: () => store.getters.authInfo,

    wfPublished: () => store.state.isPublished,

    intranetUrl: () => store.getters.intranetUrl,

    titleRules() {
      return this.title ? this.title.length > 0 : false
    },
    emailRules() {
      let result = false
      if (this.emailList.length > 0){
        result = this.emailList.find(el => el.subject === this.selectedEmail)
      }
      return result ? true : false
    },
    rules(){
      return [
        this.emailRules,
        this.titleRules
      ]
    },
  },

  watch: {
   emailList: function(){
     if (this.emailList && this.emailList.length > 0){
       let findEmail = this.emailList.find(el => el.id === Number(this.nodeInfo.res_id))
       this.selectedEmail = findEmail ? findEmail.subject : null
     }
   },

   open: function(){
     if (this.open){
       this.fillfields()
     }
   }
  },
  
  methods: {
    async saveChanges(){
      if (this.checkFormValidity() === false) {
        return
      }
      await this.putNode()
      if (this.selectedEmail){
        let findEmail = this.emailList.find(el => el.subject === this.selectedEmail)
        if (findEmail){
          store.dispatch("putAction", {
            model: "intranet.workflow.action.send.email",
            token: this.authInfo.token,
            id: findEmail.id,
            payload: {
              workflow_id: this.authInfo.workflowId
            }
          })
        }
      }
      this.updateNode()
      this.$emit("saveChanges")
      this.$emit("close")
    },

    async updateEmailList(){
      this.emailList = await store.dispatch('getActionCreateEmail', {
        fields: "['id', 'subject']",
        token: this.authInfo.token,
        domain: `'|', ('workflow_id', '=', ${this.authInfo.workflowId}), ('workflow_id', '=', False)`,
      })
    },

    openNewActionLink(){
      window.open(this.intranetUrl + "/workflow/action_type/action_email_list#new", "_blank")
    },

    async setNodeInfo(){
      let nodeData = await store.dispatch('getNode', {
        fields: "['id', 'title', 'res_model', 'res_id']",
        domain: "('id', '=', " + this.node.definition.$attrs.backId + ")",
        token: this.authInfo.token,
      })
      if (nodeData){
        this.nodeInfo = nodeData[0]
        this.fillfields()
      }
    },

    updateNode(){
      let title = rules.concatString(`${this.title}`)
      this.node.definition.name = title
    },

    fillfields(){
      if (this.nodeInfo){
        this.title = this.nodeInfo.title
        if (this.emailList.length > 0){
          let findEmail = this.emailList.find(el => el.id === Number(this.nodeInfo.res_id))
          this.selectedEmail = findEmail ? findEmail.subject : null
        }
      }
    },

    async putNode(){
      let email = this.emailList.find(el => el.subject === this.selectedEmail)
      await store.dispatch('putNode', {
        payload: {
          title: this.title,
          res_model: 'intranet.workflow.action.send.email',
          res_id: email.id
        },
        id: this.node.definition.$attrs.backId,
        token: this.authInfo.token,
      })
    },
    
    closeDialog(){
      this.$emit("close")
    },
    
    checkFormValidity() {
      return this.rules.find(el => el === false)
    },
  },
};
</script>
<style scoped>
.createBtn{
  width: 80px;
  margin-left: 20px;
  background-color: #0092ff;
}
</style>
