// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/color-wheel.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".color-list[data-v-2b440655]{display:grid;grid-template-columns:1fr 1fr;grid-template-rows:repeat(5,1fr);grid-auto-flow:column;grid-gap:.5rem;padding:.5rem}.color-list>.color-button[data-v-2b440655]{background:#fff;width:1.75rem;height:1.75rem;border-radius:50%;border:2px solid #fff;position:relative}.color-picker[data-v-2b440655]{position:absolute;right:40px;top:calc(100% - 36px)}.toggle-picker[data-v-2b440655]{background:50%/cover no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important}", ""]);
// Exports
module.exports = exports;
