<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      size="lg"
    >
      <template #modal-header>
        <h5>Aviso</h5>
      </template>

      <template>
          <h6 v-html="description"></h6>
      </template>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="closeDialog()">
          Ok
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'alertDialog',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   description: {
     type: String,
     default: '',
   }
  },

  data() {
    return {
      
    };
  },

  computed: {
    
  },

  watch: {
    nodeTitle: function(){
    }
  },
  
  methods: {
    
    closeDialog(){
      this.$emit("close")
    },
  },
};
</script>
