<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Título
            </b-input-group-prepend>
            <b-form-input
              id="title-input"
              v-model="title"
              :state="titleRules"
              :readonly="wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="titleRules">
            O título não pode estar vazio.
          </b-form-invalid-feedback>
        </b-form>

        <br/>

        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Sequência do Kanban
            </b-input-group-prepend>
            <b-form-input
              id="sequence-input"
              v-model="sequence"
              :state="sequenceRules"
              type="number"
              :readonly="entryGateway || wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="sequenceRules">
            A sequência do node não pode estar vazio e deve ser maior ou igual 0.
          </b-form-invalid-feedback>
        </b-form>
      </template>

      <template #modal-footer>
        <b-button
          v-if="!wfPublished"
          size="sm" 
          variant="success" 
          @click="saveChanges()"
        >
          Salvar
        </b-button>

        <div
          v-else
        >
          <span
            style="font-size: small; color: grey;"
          >
            Edição bloqueada, devido ao estado atual do workflow ser
          </span>
          <span
            style="font-size: small; font-weight: 800; color: grey;"
          >
            Publicado.
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import Vue from 'vue';
import process from '../inspectors/process';
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'dialogMultGateway',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
      title: '',
      sequence: '',
      nodeInfo: null,
    };
  },

  async mounted(){
    await this.setNodeInfo()
  },

  computed: {
    authInfo: () => store.getters.authInfo,

    wfPublished: () => store.state.isPublished,

    intranetUrl: () => store.getters.intranetUrl,

    titleRules() {
      return this.title ? this.title.length > 0 : false
    },

    sequenceRules() {
      return Number(this.sequence) > -1
    },

    rules(){
      return [
        this.titleRules,
        this.sequenceRules
      ]
    },

    entryGateway(){
      let node = this.node.definition.incoming ? this.node.definition.incoming[0].sourceRef : false
      if (node && node.$type === 'bpmn:ExclusiveGateway'){
        return true
      } 
      return false
    }
  },

  watch: {
   open: function(){
     if (this.open){
       this.fillfields()
     }
   }
  },
  
  methods: {
    async saveChanges(){
      if (this.checkFormValidity() === false) {
        return
      }
      await this.putNode()
      this.updateNode()
      this.$emit("saveChanges")
      this.$emit("close")
    },

    async setNodeInfo(){
      let nodeData = await store.dispatch('getNode', {
        fields: "['id', 'title', 'sequence', 'res_model', 'res_id']",
        domain: "('id', '=', " + this.node.definition.$attrs.backId + ")",
        token: this.authInfo.token,
      })
      if (nodeData){
        this.nodeInfo = nodeData[0]
        this.fillfields()
      }
    },

    updateNode(){
      let title = rules.concatString(`(${this.sequence})\n ${this.title}`)
      this.node.definition.name = title
    },

    fillfields(){
      if (this.nodeInfo){
        this.title = this.nodeInfo.title
        this.sequence = this.nodeInfo.sequence
      }
    },

    async putNode(){
      await store.dispatch('putNode', {
        payload: {
          title: this.title,
          sequence: this.sequence,
        },
        id: this.node.definition.$attrs.backId,
        token: this.authInfo.token, 
      })
    },
    
    closeDialog(){
      this.$emit("close")
    },
    
    checkFormValidity() {
      return this.rules.find(el => el === false)
    },
  },
};
</script>