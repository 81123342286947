var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('Loop Characteristics')}},[_c('b-form-radio-group',{attrs:{"id":"radio-group-loop-characteristics","options":_vm.loopOptions,"stacked":"","name":"radio-group-loop-characteristics"},on:{"change":_vm.changeLoopType},model:{value:(_vm.loopType),callback:function ($$v) {_vm.loopType=$$v},expression:"loopType"}})],1),(
      _vm.loopType === 'parallel_mi' ||
        _vm.loopType === 'sequential_mi'
    )?[_c('b-form-group',{attrs:{"label":_vm.$t('Type')}},[_c('b-form-radio',{attrs:{"name":"multiType-radio","value":"loopCardinality"},on:{"change":_vm.changeMultiType},model:{value:(_vm.multiType),callback:function ($$v) {_vm.multiType=$$v},expression:"multiType"}},[_vm._v("Numeric Expression")]),_c('b-form-radio',{attrs:{"name":"multiType-radio","value":"inputData"},on:{"change":_vm.changeMultiType},model:{value:(_vm.multiType),callback:function ($$v) {_vm.multiType=$$v},expression:"multiType"}},[_vm._v("Request Data Array")])],1),(_vm.multiType === 'loopCardinality')?_c('b-form-group',{attrs:{"id":"group-loopCardinality","label":_vm.$t('Loop Cardinality'),"label-for":"loopCardinality","description":_vm.$t(
          'A numeric Expression that defines the number of Activity instances that will be created. Ex. 3 or Variable'
        )}},[_c('b-form-input',{attrs:{"id":"loopCardinality","type":"text","placeholder":"numeric expression"},on:{"change":_vm.changeLoopCardinality},model:{value:(_vm.loopCardinality),callback:function ($$v) {_vm.loopCardinality=$$v},expression:"loopCardinality"}})],1):_vm._e(),(_vm.multiType === 'inputData')?_c('b-form-group',{attrs:{"id":"group-inputData","label":_vm.$t('Source Data Variable'),"label-for":"inputData","description":_vm.$t(
          'Variable used to determine the number of Activity instances, one per item in the array.'
        )}},[_c('b-form-input',{attrs:{"id":"inputData","type":"text","placeholder":"arrayVariable"},on:{"change":_vm.changeInputData},model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})],1):_vm._e(),_c('b-form-group',{attrs:{"id":"group-outputData","label":_vm.$t('Output Data Variable'),"label-for":"outputData","description":_vm.$t(
          'Specifies the output request data variable array, which will be produced as a result of the multi-instance.'
        )}},[_c('b-form-input',{attrs:{"id":"outputData","type":"text","placeholder":"arrayVariable"},on:{"change":_vm.changeOutputData},model:{value:(_vm.outputData),callback:function ($$v) {_vm.outputData=$$v},expression:"outputData"}})],1),_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-advanced-multi-instance`),expression:"`collapse-advanced-multi-instance`"}],staticClass:"text-black",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showAdvanced=!_vm.showAdvanced}}},[_c('i',{staticClass:"far",class:{ 'fa-plus-square': !_vm.showAdvanced, 'fa-minus-square': _vm.showAdvanced }}),_vm._v(" "+_vm._s(_vm.$t('Advanced'))+" ")]),_c('b-collapse',{model:{value:(_vm.showAdvanced),callback:function ($$v) {_vm.showAdvanced=$$v},expression:"showAdvanced"}},[(_vm.multiType === 'inputData')?_c('b-form-group',{attrs:{"id":"group-inputDataItem","label":_vm.$t('Source Data Item Variable'),"label-for":"inputDataItem","description":_vm.$t(
            'Represents a single item of the array received by each Activity instance. If not defined Task receives item as root data.'
          )}},[_c('b-form-input',{attrs:{"id":"inputDataItem","type":"text","placeholder":"screen root data"},on:{"change":_vm.changeInputDataItem},model:{value:(_vm.inputDataItem),callback:function ($$v) {_vm.inputDataItem=$$v},expression:"inputDataItem"}})],1):_vm._e(),_c('b-form-group',{attrs:{"id":"group-outputDataItem","label":_vm.$t('Output Data Item Variable'),"label-for":"outputDataItem","description":_vm.$t(
            'Represents a single item of the array that will be produced by the multi-instance. If not defined all Task instance values will be stored inside output variable.'
          )}},[_c('b-form-input',{attrs:{"id":"outputDataItem","type":"text","placeholder":"screen root data"},on:{"change":_vm.changeOutputDataItem},model:{value:(_vm.outputDataItem),callback:function ($$v) {_vm.outputDataItem=$$v},expression:"outputDataItem"}})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }