export default {
  oneEntryConector(sequenceFlow){
    let targetRef = sequenceFlow.definition.targetRef
    let existFlow = this.searchFlows(targetRef.incoming)
    if (existFlow.result){
      return {
        result: false, 
        message: "O node <b>" + targetRef.name + "</b>, permite apenas <b>1</b> conector de entrada."
      }
    }
    return {result: true}
  },
  oneExitConector(genericFlow){
    let sourceRef = genericFlow.definition.sourceRef
    let existFlow = this.searchFlows(sourceRef.outgoing)
    if (existFlow.result){
      return {
        result: false, 
        message: "O node <b>" + sourceRef.name + "</b>, permite apenas <b>1</b> conector de saída."
      }
    }
    return {result: true}
  },
  twoExitConector(genericFlow){
    let sourceRef = genericFlow.definition.sourceRef
    let existFlow = this.searchFlows(sourceRef.outgoing)
    if (existFlow.result && existFlow.flowQtd === 2){
      return {
        result: false, 
        message: "O node <b>" + sourceRef.name + "</b>, permite apenas <b>2</b> conectores de saída."
      }
    }
    return {result: true}
  },
  searchFlows(flows){
    let objRes = {
      result: false,
      flowQtd: 0, 
    }
    if (flows){
      flows.forEach(el => {
        if (el.$attrs.backId){
          objRes.result = true
          objRes.flowQtd++
        }
      })
    }
    return objRes
  },
  twoConnectedOutputs(node){
    let outgoing = node.definition.outgoing ? node.definition.outgoing : []
    if (outgoing.length < 2){
      return {
        result: false, 
        message: "Para configurar o node <b>" + node.definition.name + "</b>, precisa estar com as <b>2 saídas</b> conectadas."
      }
    }
    return {result: true}
  },
  entryIsMultGateway(sequenceFlow){
    let entryNode = sequenceFlow.definition.sourceRef
    if (entryNode.$type != 'bpmn:ExclusiveGateway'){
      return {
        result: false, 
        message: "O node <b>" + sequenceFlow.definition.targetRef.name + "</b>, pode ser conectado somente com o node <b>Mult Gateway</b>."
      }
    }
    return {result: true}
  },
  entryNotIsMultGateway(genericFlow){
    let incoming = genericFlow.definition.sourceRef.incoming ? genericFlow.definition.sourceRef.incoming[0] : null
    if (incoming && incoming.sourceRef.$type === 'bpmn:ExclusiveGateway'){
      return {
        result: false, 
        message: "O node <b>" + genericFlow.definition.sourceRef.name + "</b>, não pode ter saídas pois está conectado a um <b>Mult Gateway</b>. Para continuar o fluxo conecte o seu <b>Multiple Gateway</b> em um <b>Mono Gateway</b>."
      }
    }
    return {result: true}
  },
  blockMultGateway(sequenceFlow){
    let targetNode = sequenceFlow.definition.targetRef
    let sourceNode = sequenceFlow.definition.sourceRef
    console.log(sequenceFlow)
    if (sourceNode.$type === 'bpmn:ExclusiveGateway' && targetNode.$type === 'bpmn:ExclusiveGateway'){
      return {
        result: false, 
        message: "Não é possível conectar o node <b>" + sequenceFlow.definition.sourceRef.name + "</b> em outro node do <b>mesmo tipo</b>."
      }
    }
    return {result: true}
  },
  monoGatewayIsConnected(sequenceFlow){
    let sourceNode = sequenceFlow.definition.sourceRef
    let returnFunc = {result: true}
    if (sourceNode.outgoing){
      let monoCount = 0
      sourceNode.outgoing.forEach(flow => {
        if (flow.targetRef.$type === "bpmn:ParallelGateway"){
          monoCount++
          if (monoCount > 1){
            returnFunc = {
              result: false,
              message: "O node <b>" + sourceNode.name + "</b>, já está conectado em um <b>Mono Gateway</b>."
            }
          }
        }
      })
    }
    return returnFunc
  },
  concatString(string, qtd){
    let max =  qtd ? qtd : 20
    if (string.length > max){
      return string.substring(0, max) + "..."
    } else {
      return string
    }
  }
};