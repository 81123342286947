<template>
  <b-container id="modeler-app" class="h-100 container position-relative p-0">
    <b-card no-body class="h-100 rounded-0">
      <b-card-header class="d-flex align-items-center header">
        <b-card-text class="m-0 font-weight-bolder">
          {{ wfTitle ? wfTitle : "Carregando..." }}
        </b-card-text>

        <!-- <div class="ml-auto">
          <b-btn variant="secondary" size="sm" v-b-modal="'uploadmodal'" class="mr-2">
            <i class="fas fa-upload mr-1"/>
            {{ $t('Upload XML') }}
          </b-btn>
        </div> -->
      </b-card-header>
      <b-card-body class="overflow-hidden position-relative p-0 vh-100">
        <modeler 
          :newWorkflow="newWorkflow"
          ref="modeler"
          @set-xml-manager="xmlManager = $event"
          @validate="validationErrors = $event"
          @warnings="warnings = $event"
          :decorations="decorations"
        />
      </b-card-body>
      <validation-status ref="validationStatus"
        :validation-errors="validationErrors"
        :warnings="warnings"
        :xml-manager="xmlManager"
      >
        <component v-for="(component, index) in validationBar" :key="index" :is="component" />
      </validation-status>
    </b-card>

    <b-modal
      ref="uploadmodal"
      id="uploadmodal"
      :title="$t('Upload BPMN File')"
      :cancel-title="$t('Cancel')"
      :ok-title="$t('Upload')"
      :ok-disabled="!uploadedXml"
      cancel-variant="outline-secondary"
      ok-variant="secondary"
      @hidden="this.clearUpload"
      @ok="this.loadXmlIntoModeler"
    >
      <file-upload class="btn btn-primary" v-model="xmlFile">
        {{ $t('Select file') }}
      </file-upload>

      <span class="ml-3" v-if="xmlFile[0]">{{ xmlFile[0].name }}</span>
    </b-modal>
  </b-container>
</template>

<script>
import Modeler from './components/modeler/Modeler.vue';
import FileUpload from 'vue-upload-component';
import ValidationStatus from '@/components/validationStatus/ValidationStatus';
import undoRedoStore from '@/undoRedoStore';
import store from '@/store';

const reader = new FileReader();

export default {
  name: 'ModelerApp',
  components: {
    Modeler,
    FileUpload,
    ValidationStatus,
  },
  data() {
    return {
      validationBar: [],
      decorations: {
        borderOutline: {},
      },
      validationErrors: {},
      uploadedXml: null,
      xmlFile: [],
      warnings: [],
      xmlManager: null,
      newWorkflow: false,
      wfTitle: "",
    };
  },
  watch: {
    xmlFile([fileObject]) {
      if (fileObject) {
        reader.readAsText(fileObject.file);
      }
    },
  },
  methods: {
    loadXmlIntoModeler() {
      this.$refs.modeler.loadXML(this.uploadedXml);
      undoRedoStore.dispatch('pushState', this.uploadedXml);
    },
    clearUpload() {
      this.uploadedXml = null;
      this.xmlFile = [];
    },
    setUploadedXml(event) {
      this.uploadedXml = event.target.result;
    },

    //.:Ilios Sistemas:.//
    getTokenAndWorkflowUrl(){
      let url = String(window.location.href)
      let result = {token: null, workflowId: null}
      if (url.length < 500){
        let startWflowId = 0
        let startTokenId = 0
        for (let i=0;i<url.length;i++){
          if (url[i] === '#'){
            startWflowId = i
            url = url.substring(0, startWflowId+1) + window.atob(url.substring(startWflowId+1, url.length))
          }
          url[i] === '?' ? startTokenId = i : false
        }
        if (startWflowId && startTokenId){
          result.workflowId = url.substring(startWflowId+1, startTokenId)
          result.token = url.substring(startTokenId+1, url.length)
        }
      }
      return result
    },
  },
  created() {
    reader.onload = this.setUploadedXml;
  },
  async mounted() {
    //.:Ilios Sistemas:.//
    let authInfo = await this.getTokenAndWorkflowUrl()
    store.commit('SET_AUTH_INFO', {data: authInfo})
    ///////.:End:.///////
    let workflow = await store.dispatch('getWorkflow', {
      fields: "['id', 'title', 'xml', 'version', 'state']",
      domain: "('id', '=', " + authInfo.workflowId + ")",
      token: authInfo.token
    })
    if (workflow){
      if (workflow[0].xml){
        this.uploadedXml = workflow[0].xml
        this.loadXmlIntoModeler()
        this.newWorkflow = false
      } else {
        this.newWorkflow = true
      }

      if (workflow.length > 0){
        this.wfTitle = `Versão: ${workflow[0].version} | ${workflow[0].title}`
      }
    }
    window.ProcessMaker.$modeler = this.$refs.modeler;
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
}
</style>
