<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <h6>Siglas</h6>
        <b-container
          v-if="dynamicFields.length === 0"
          class="notFoundField"
        >
          <p
            v-text="'Não foi encontrado nenhuma sigla(@) cadastrada.'"
            style="color: grey;"
          />
          <b-button
            class="addBtn" 
            size="sm"
            v-text="'Inserir (+)'"
            :disabled="wfPublished"
            @click="addNewField()"
            />
        </b-container>
        <b-form 
          v-for="(field, index) in dynamicFields"
          :key="index"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              @
            </b-input-group-prepend>
            <b-form-input
              id="field-name-input"
              v-model="field.name"
              :readonly="wfPublished"
              @input="setToUpdate(field)"
            ></b-form-input>
            <b-button
              v-if="dynamicFields.length === index+1"
              class="addBtn" 
              size="sm"
              :disabled="wfPublished"
              v-text="'+'"
              @click="addNewField()"
            />
          </b-input-group>
          <br/>
        </b-form>
      </template>

      <template #modal-footer>
        <b-button
          v-if="!wfPublished"
          size="sm" 
          variant="success" 
          @click="saveChanges()"
        >
          Salvar
        </b-button>

        <div
          v-else
        >
          <span
            style="font-size: small; color: grey;"
          >
            Edição bloqueada, devido ao estado atual do workflow ser
          </span>
          <span
            style="font-size: small; font-weight: 800; color: grey;"
          >
            Publicado.
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'dialogAnnotation',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
   
    }
  },

  mounted(){
    store.dispatch("getDynamicField", {
      token: this.authInfo.token,
      fields: "['name']",
      domain: `('workflow_id', '=', ${this.authInfo.workflowId})`
    })
  },

  computed: {
    nodeTitle: function(){
      if (this.node){
        this.title = this.node.definition.text
      }
    },
    authInfo: () => store.getters.authInfo,
    dynamicFields: () => store.getters.dynamicFields,
    wfPublished: () => store.state.isPublished,
  },

  watch: {
    open: function(){
     if (this.open){
       this.title = this.node.definition.text
     }
   }
  },
  
  methods: {
    async saveChanges(){
      let newFields = this.dynamicFields.filter(el => el.new === true)
      let putFields = this.dynamicFields.filter(el => el.new === undefined)
      this.putFields(putFields)
      this.postFields(newFields)
      // this.node.definition.text = this.title
      this.$emit("saveChanges")
      this.$emit("close")
    },

    postFields(newFields){
      if (newFields){
        newFields.forEach(field => {
          if (field.name){
            store.dispatch('postDynamicField', {
              data: {
                name: field.name,
                workflow_id: this.authInfo.workflowId,
              },
              token: this.authInfo.token
            })
          }
        })
      }
    },

    putFields(putFields){
      if (putFields){
        putFields.forEach(field => {
          if (field.name && field.toUpdate != undefined){
            store.dispatch('putDynamicField', {
              payload: {
                name: field.name,
              },
              id: field.id,
              token: this.authInfo.token,
            })
          }
        })
      }
    },

    setToUpdate(field){
      field.name = field.name.toLowerCase()
      if (field.hasOwnProperty("toUpdate")){
        field.toUpdate = true
      } else {
        field["toUpdate"] = true
      }
    },

    addNewField(){
      let newId = 0
      this.dynamicFields.forEach(el => {
        newId = el.id > newId ? el.id : newId
      })
      store.commit("SET_DYNAMIC_FIELD", {
        field: {
          id: newId+1,
          name: '',
          workflow_id: Number(this.authInfo.workflowId),
          new: true,
        }
      })
    },
    
    closeDialog(){
      this.$emit("close")
    },
  },
};
</script>
<style scoped>
.addBtn{
  width: 80px;
  margin-left: 20px;
  background-color: #0092ff;
}
.notFoundField{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}
</style>