<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Título"
            label-for="title-input"
            invalid-feedback="Título é obrigatório."
            :state="titleState"
          >
            <b-form-input
              id="title-input"
              v-model="title"
              :state="titleState"
              required
            ></b-form-input>
          </b-form-group>
        </form>
      </template>

      <template #modal-footer>
        <b-button size="sm" variant="success" @click="saveChanges()">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'dialogAnnotation',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
      title: '',
      titleState: null,
    };
  },

  computed: {
    nodeTitle: function(){
      if (this.node){
        this.title = this.node.definition.text
      }
    }
  },

  watch: {
    open: function(){
     if (this.open){
       this.title = this.node.definition.text
     }
   }
  },
  
  methods: {
    saveChanges(){
      if (!this.checkFormValidity()) {
        return
      }
      this.node.definition.text = this.title
      this.$emit("saveChanges")
      this.$emit("close")
    },
    
    closeDialog(){
      this.$emit("close")
    },
    
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.titleState = valid
      return valid
    },

    resetModal() {
      this.title = ''
      this.titleState = null
    },
  },
};
</script>
