import Vue from 'vue';
import Vuex from 'vuex';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import axios from 'axios';
// import createLogger from 'vuex/dist/logger';
import iliosState from './iliosState.js'

Vue.use(Vuex);
// const debug = process.env.NODE_ENV !== 'production';

function setDefinitionPropertyReactive(definition, key, value) {
  if (definition.hasOwnProperty(key)) {
    definition.set(key, value);
    return;
  }

  delete Object.getPrototypeOf(definition)[key];
  Vue.set(definition, key, value);
}

function removeRef(state, ref, callBack) {
  state.nodes.filter(({ definition }) => (
    definition.$type === 'bpmn:IntermediateCatchEvent' ||
    definition.$type === 'bpmn:StartEvent'
  )
    && definition.eventDefinitions && definition.eventDefinitions.some(callBack)).forEach(({ definition }) => {
    definition.eventDefinitions[0][ref] = null;
  });
}

export default new Vuex.Store({
  // strict: debug,
  // plugins: debug ? [createLogger()] : [], // set logger only for development

  state: {
    graph: null,
    paper: null,
    highlightedNodes: [],
    lastNodeCreated: null,
    nodes: [],
    rootElements: [],
    autoValidate: false,
    globalProcesses: [],
    allowSavingElementPosition: true,
    inProcess: false,

    //////////////////.:Ilios Sistemas:.////////////////
    //Variaveis criadas para a customização do moduler//
    ////////////////////////////////////////////////////
    apiState: iliosState.INIT,
    apiStateXml: iliosState.INIT,
    messageError: '',
    authInfo: {
      token: null, 
      workflowId: null,
    },
    dynamicFields: [],
    isPublished: false,
  },

  getters: {
    nodes: state => state.nodes,
    lastNodeCreated: state => state.lastNodeCreated,
    highlightedNodes: state => state.highlightedNodes,
    nodeShape: state => node => {
      return state.graph.getCells().find(cell => cell.component && cell.component.node === node);
    },
    highlightedShapes: (state, getters) => {
      return getters.highlightedNodes
        .filter(node => node.type !== 'processmaker-modeler-process')
        .map(getters.nodeShape);
    },
    rootElements: state => state.rootElements,
    autoValidate: state => state.autoValidate,
    globalProcesses: state => state.globalProcesses,
    globalProcessEvents: (state, getters) => flatten(getters.globalProcesses.map(process => process.events)),
    allowSavingElementPosition: state => state.allowSavingElementPosition,

    //////////////////.:Ilios Sistemas:.////////////////
    //Getters criados para a customização do moduler//
    ////////////////////////////////////////////////////
    getApiState: state => {
      return state.apiState;
    },
    getMessageError: state => {
      return state.messageError;
    },
    authInfo: state => state.authInfo,
    dynamicFields: state => state.dynamicFields,
    intranetUrl: state => process.env.VUE_APP_INTRANET_URL,
    apiStateXml: state => state.apiStateXml,
    inProcess: state => state.inProcess,
    isPublished: state => state.isPublished,
  },

  mutations: {
    preventSavingElementPosition(state) {
      state.allowSavingElementPosition = false;
    },
    allowSavingElementPosition(state) {
      state.allowSavingElementPosition = true;
    },
    setAutoValidate(state, autoValidate) {
      state.autoValidate = autoValidate;
    },
    setRootElements(state, rootElements) {
      state.rootElements = rootElements;
    },
    updateNodeBounds(state, { node, bounds }) {
      Object.entries(bounds).forEach(([key, val]) => {
        if (key === '$type') {
          return;
        }

        node.diagram.bounds.set(key, val);
      });
    },
    updateNodeProp(state, { node, key, value }) {
      setDefinitionPropertyReactive(node.definition, key, value);

      if (value == null) {
        Vue.delete(node.definition, key);
      }
    },
    clearNodes(state) {
      state.nodes = [];
    },
    highlightNode(state, node) {
      state.highlightedNodes = node ? [node] : [];
    },
    addToHighlightedNodes(state, nodes) {
      state.highlightedNodes = uniq([...state.highlightedNodes, ...nodes]);
    },
    addNode(state, node) {
      /* Add an unchanging ID that Vue can use to track the component
       * (used in v-for when rendering the node). Relying on the
       * definition ID will cause issues as the user can change the
       * ID of elements. */
      node._modelerId = '_modelerId_' + node.definition.get('id');

      state.nodes.push(node);
    },
    removeNode(state, node) {
      const index = state.nodes.indexOf(node);

      if (index !== -1) {
        state.nodes.splice(index, 1);
      }
    },
    removeMessageRef(state, message) {
      removeRef(state, 'messageRef', ({ messageRef }) => messageRef === message);
    },
    removeSignalRef(state, signal) {
      removeRef(state, 'signalRef', ({ signalRef }) => signalRef === signal);
    },
    setGraph(state, graph) {
      state.graph = graph;
    },
    setPaper(state, paper) {
      state.paper = paper;
    },
    setGlobalProcesses(state, globalProcesses) {
      state.globalProcesses = globalProcesses;
    },

    //////////////////.:Ilios Sistemas:.////////////////
    //Mutations criados para a customização do moduler//
    ////////////////////////////////////////////////////
    SET_API_STATE(state, { apiState }) {
      state.apiState = apiState
    },

    SET_API_STATE_XML(state, { apiStateXml }) {
      state.apiStateXml = apiStateXml
    },

    SET_LAST_NODE_CREATED(state, { newNode }) {
      state.lastNodeCreated = newNode
    },

    SET_MESSAGE_ERROR(state, { message }) {
      state.messageError = message
    },

    SET_AUTH_INFO(state, {data}){
      state.authInfo = data
    },

    SET_IN_PROCESS(state, { value }){
      state.inProcess = value
    },

    SET_IS_PUBLISHED(state, { value }){
      state.isPublished = value
    },

    SET_DYNAMIC_FIELD(state, { field }){
      if (field.new === undefined){
        let oldField = state.dynamicFields.find(el => el.id === field.id)
        Object.keys(field).forEach(key => {
          if (oldField.hasOwnProperty(key)){
            oldField[key] = field[key]
          }
        })
      } else {
        state.dynamicFields.push(field)
      }
    },

    UPDATE_DYNAMIC_FIELD(state, { oldField, newField }){
      oldField['new'] != undefined ? delete oldField['new'] : false
      oldField['toUpdate'] != undefined ? delete oldField['toUpdate'] : false
      Object.keys(newField).forEach(key => {
        if (oldField.hasOwnProperty(key)){
          oldField[key] = newField[key]
        }
      })
    },

    SET_DYNAMIC_FIELD_LIST(state, { fieldList }){
      state.dynamicFields = fieldList
    }
  },

  actions: {
    async fetchGlobalProcesses({ commit }) {
      try {
        const { data } = await window.ProcessMaker.apiClient.get('processes', {
          params: {
            order_direction: 'asc',
            per_page: 1000,
            status: 'active',
            include: 'events',
          },
        });
        commit('setGlobalProcesses', data.data);
      } catch (error) {
        /* Ignore error */
      }
    },


    ///////////////.:Ilios Sistemas:.//////////////
    //Actions usados para conexões com o back-end//
    ///////////////////////////////////////////////
    async postNewNode(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/iliosrest/post/intranet.node',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 200) {
          result = response.data.data
          context.commit('SET_LAST_NODE_CREATED', {
            newNode: result
          });
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async createUpdateNode(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/create_update/node',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async createConectorBackend(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/post/new_conector',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 201) {
          result = response.data.data
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async postNewConector(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/iliosrest/post/intranet.node.conector',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 200) {
          result = response.data.data
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async postNewActionCondition(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/iliosrest/post/intranet.wf.act.cond',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 200) {
          result = response.data.data
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async postDynamicField(context, { data, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.post(process.env.VUE_APP_BASE_URL + '/iliosrest/post/workflow.dynamic.field',
        {
          data : data
        },
        {
          headers: {
            'Content-Type' : 'form/x-www-form-urlencoded',
            'charset' : 'utf-8',
            'Access-Token': token
          }
        }
      ).then(response => {
        if(response['status'] == 200) {
          result = response.data.data
          let oldField = this.getters.dynamicFields.find(el => el.name === data.name)
          data.id = result.id
          context.commit("UPDATE_DYNAMIC_FIELD", {
            oldField: oldField,
            newField: data,
          })
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        var messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async deleteNode(context, { nodeId, token }) {
      let result = null
      context.commit('SET_API_STATE', { apiState: iliosState.LOADING });
     
      await axios.delete(process.env.VUE_APP_BASE_URL + '/iliosrest/delete/intranet.node/' + nodeId, {
        params: {
          context : {
            "lang": "pt_BR",
            "tz" : Intl.DateTimeFormat().resolvedOptions().timeZone
          },
        },
        headers: {
          'Access-Token': token
        }
      }).then(response => {
        if(response['status'] == 200) {
          result = response.data
          context.commit('SET_API_STATE', { apiState: iliosState.LOADED });
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        let messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async deleteActCondition(context, { actId, token }) {
      let result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.delete(process.env.VUE_APP_BASE_URL + '/iliosrest/delete/intranet.wf.act.cond/' + actId, {
        params: {
          context : {
            "lang": "pt_BR",
            "tz" : Intl.DateTimeFormat().resolvedOptions().timeZone
          },
        },
        headers: {
          'Access-Token': token
        }
      }).then(response => {
        if(response['status'] == 200) {
          result = response.data
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
          
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        let messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async deleteConector(context, { conectorId, token }) {
      let result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      });
      await axios.delete(process.env.VUE_APP_BASE_URL + '/iliosrest/delete/intranet.node.conector/' + conectorId, {
        params: {
          context : {
            "lang": "pt_BR",
            "tz" : Intl.DateTimeFormat().resolvedOptions().timeZone
          },
        },
        headers: {
          'Access-Token': token
        }
      }).then(response => {
        if(response['status'] == 200) {
          result = response.data
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          });
          
        }
      }).catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        });
        let messageError = e.response ? e.response.data.message : e
        console.log(messageError)
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async putWorkFlow(context, { payload, id, token, lastNodeCreated }) {
      var result = null
      context.commit('SET_API_STATE_XML', {
        apiStateXml: iliosState.LOADING
      })
      let config = {
        headers: {
          'Content-Type' : 'form/x-www-form-urlencoded',
          "Accept": "application/json",
          'charset' : 'utf-8',
          'Access-Token': token,
        }
      }
      payload["lastNodeCreated"] = lastNodeCreated
      context.commit('SET_LAST_NODE_CREATED', {
        newNode: null
      });
      var data =  {
        data: payload
      }
      let url = process.env.VUE_APP_BASE_URL + '/iliosrest/put/intranet.workflow/' + id;
  
      await axios.put(url, data=data, config)
      .then(response => {
        if(response['status'] == 200) {
          result = payload
          context.commit('SET_API_STATE_XML', {
            apiStateXml: iliosState.LOADED
          })
        }}
      )
      .catch(e => {
        context.commit('SET_API_STATE_XML', {
          apiStateXml: iliosState.LOADED
        })
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async putNode(context, { payload, id, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      })
      let config = {
        headers: {
          'Content-Type' : 'form/x-www-form-urlencoded',
          "Accept": "application/json",
          'charset' : 'utf-8',
          'Access-Token': token,
        }
      }
      var data =  {
        data: payload
      }
      let url = process.env.VUE_APP_BASE_URL + '/iliosrest/put/intranet.node/' + id;
  
      await axios.put(url, data=data, config)
      .then(response => {
        if(response['status'] == 200) {
          result = payload
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          })
        }}
      )
      .catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        })
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
        return e
      })
      return result
    },

    async putActCondition(context, { payload, id, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      })
      let config = {
        headers: {
          'Content-Type' : 'form/x-www-form-urlencoded',
          "Accept": "application/json",
          'charset' : 'utf-8',
          'Access-Token': token,
        }
      }
      var data =  {
        data: payload
      }
      let url = process.env.VUE_APP_BASE_URL + '/iliosrest/put/intranet.wf.act.cond/' + id;
  
      await axios.put(url, data=data, config)
      .then(response => {
        if(response['status'] == 200) {
          result = payload
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          })
        }}
      )
      .catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        })
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async putAction(context, { payload, id, token, model }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      })
      let config = {
        headers: {
          'Content-Type' : 'form/x-www-form-urlencoded',
          "Accept": "application/json",
          'charset' : 'utf-8',
          'Access-Token': token,
        }
      }
      var data =  {
        data: payload
      }
      let url = process.env.VUE_APP_BASE_URL + `/iliosrest/put/${model}/${id}`
  
      await axios.put(url, data=data, config)
      .then(response => {
        if(response['status'] == 200) {
          result = payload
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          })
        }}
      )
      .catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        })
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async putDynamicField(context, { payload, id, token }) {
      var result = null
      context.commit('SET_API_STATE', {
        apiState: iliosState.LOADING
      })
      let config = {
        headers: {
          'Content-Type' : 'form/x-www-form-urlencoded',
          "Accept": "application/json",
          'charset' : 'utf-8',
          'Access-Token': token,
        }
      }
      var data =  {
        data: payload
      }
      let url = process.env.VUE_APP_BASE_URL + '/iliosrest/put/workflow.dynamic.field/' + id;
  
      await axios.put(url, data=data, config)
      .then(response => {
        if(response['status'] == 200) {
          result = payload
          let oldField = this.getters.dynamicFields.find(el => el.id === id)
          context.commit("UPDATE_DYNAMIC_FIELD", {
            oldField: oldField,
            newField: payload,
          })
          context.commit('SET_API_STATE', {
            apiState: iliosState.LOADED
          })
        }}
      )
      .catch(e => {
        context.commit('SET_API_STATE', {
          apiState: iliosState.LOADED
        })
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getWorkflow(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.workflow',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data

          if (result[0].state == 'published'){
            context.commit('SET_IS_PUBLISHED', { value: true })
          }
        }      
      })    
      .catch(e => {      
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },
    
    async getNode(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.node',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }      
      })    
      .catch(e => {      
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getActionCreateTask(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.workflow.action.create.task',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
          order: "write_date DESC",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }      
      })    
      .catch(e => {      
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getActionCreateEmail(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.workflow.action.send.email',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
          order: "write_date DESC",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }      
      })    
      .catch(e => {
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getActionSendForm(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.workflow.action.execute.form',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
          order: "write_date DESC",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }      
      })    
      .catch(e => {
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getActCondition(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/intranet.wf.act.cond',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
          order: "write_date DESC",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
        }      
      })    
      .catch(e => {
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },

    async getDynamicField(context, { domain, token, fields }) {
      var result = null
      await axios.get(process.env.VUE_APP_BASE_URL + '/iliosrest/get/workflow.dynamic.field',
      {
        params: {
          db: process.env.VUE_APP_DATA_BASE,
          fields: fields,
          domain: "[" + domain + "]",
        },
        headers: {
         'Content-Type' : 'form/x-www-form-urlencoded', 
         'charset' : 'utf-8',
         'Access-Token': token
        }
      })
      .then(response => {
        if(response['status'] == 200) {
          result = response.data.data
          context.commit("SET_DYNAMIC_FIELD_LIST", {
            fieldList: result
          })
        }      
      })    
      .catch(e => {      
        console.log(e);
        var messageError = e.response ? e.response.data.message : e
        context.commit('SET_MESSAGE_ERROR', { message: messageError })
      })
      return result
    },
  },
});
