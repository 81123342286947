<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <template #modal-header>
        <h5>Configuração do node</h5>
        <b-button size="sm" variant="danger" @click="closeDialog()">
          X
        </b-button>
      </template>

      <template>
        <b-form  @submit.stop.prevent>
          <b-input-group>
            <b-input-group-prepend is-text>
              Título
            </b-input-group-prepend>
            <b-form-input
              id="title-input"
              v-model="title"
              :state="titleRules"
              :readonly="wfPublished"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="titleRules">
            O título não pode estar vazio.
          </b-form-invalid-feedback>
        </b-form>

        <br/>

        <b-form  @submit.stop.prevent>
           <b-input-group>
            <b-input-group-prepend is-text>
              Modelo da tarefa
            </b-input-group-prepend>
            <b-form-input 
              list="task-list-id"
              v-model="selectedTask"
              :state="taskRules"
              :readonly="wfPublished"
              @focus="updateTaskList()"
            ></b-form-input>
            <b-button 
              class="createBtn" 
              size="sm"
              :disabled="wfPublished"
              @click="openNewActionLink()"
            >
              Criar
            </b-button>

            <datalist id="task-list-id">
              <option v-for="task in taskList" :key="task.id">{{ task.title ? task.title : '' }}</option>
            </datalist>
          </b-input-group>
          <b-form-invalid-feedback :state="taskRules">
            Selecione um modelo de tarefa.
          </b-form-invalid-feedback>
        </b-form>

        <br/>

        <b-form
          v-show="!entryGateway"
          @submit.stop.prevent
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              Sequência do Kanban
            </b-input-group-prepend>
            <b-form-input
              id="sequence-input"
              v-model="sequence"
              :state="sequenceRules"
              :readonly="wfPublished"
              type="number"
            ></b-form-input>
          </b-input-group>
          <b-form-invalid-feedback :state="sequenceRules">
            A sequência do node não pode estar vazio e deve ser maior ou igual 0.
          </b-form-invalid-feedback>
        </b-form>
      </template>

      <template #modal-footer>
        <b-button
          v-if="!wfPublished"
          size="sm" 
          variant="success" 
          @click="saveChanges()"
        >
          Salvar
        </b-button>

        <div
          v-else
        >
          <span
            style="font-size: small; color: grey;"
          >
            Edição bloqueada, devido ao estado atual do workflow ser
          </span>
          <span
            style="font-size: small; font-weight: 800; color: grey;"
          >
            Publicado.
          </span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import Vue from 'vue';
import process from '../inspectors/process';
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'dialogTask',

  props: {
   open: {
     type: Boolean,
     default: false,
   },
   node: {
     type: Object,
     default: null,
   }
  },

  data() {
    return {
      selectedTask: null,
      title: '',
      sequence: '',
      nodeInfo: null,
      taskList: [],
    };
  },

  async mounted(){
    await this.setNodeInfo()
    this.updateTaskList()
    
  },

  computed: {
    authInfo: () => store.getters.authInfo,

    wfPublished: () => store.state.isPublished,

    intranetUrl: () => store.getters.intranetUrl,

    titleRules() {
      return this.title ? this.title.length > 0 : false
    },

    sequenceRules() {
      return Number(this.sequence) > -1
    },

    taskRules() {
      let result = false
      if (this.taskList && this.taskList.length > 0){
        result = this.taskList.find(el => el.title === this.selectedTask)
      }
      return result ? true : false
    },
    rules(){
      return [
        this.taskRules,
        this.titleRules,
        this.sequenceRules
      ]
    },
    entryGateway(){
      let node = this.node.definition.incoming ? this.node.definition.incoming[0].sourceRef : false
      if (node && node.$type === 'bpmn:ExclusiveGateway'){
        return true
      } 
      return false
    }
  },

  watch: {
   taskList: function(){
     if (this.taskList && this.taskList.length > 0){
       let findTask = this.taskList.find(el => el.id === Number(this.nodeInfo.res_id))
       this.selectedTask = findTask ? findTask.title : null
     }
   },

   open: function(){
     if (this.open){
       this.fillfields()
     }
   }
  },
  
  methods: {
    async saveChanges(){
      if (this.checkFormValidity() === false) {
        return
      }
      await this.putNode()
      if (this.selectedTask){
        let findTask = this.taskList.find(el => el.title === this.selectedTask)
        if (findTask){
          store.dispatch("putAction", {
            model: "intranet.workflow.action.create.task",
            token: this.authInfo.token,
            id: findTask.id,
            payload: {
              workflow_id: this.authInfo.workflowId
            }
          })
        }
      }
      this.updateNode()
      this.$emit("saveChanges")
      this.$emit("close")
    },

    async updateTaskList(){
      this.taskList = await store.dispatch('getActionCreateTask', {
        fields: "['id', 'title']",
        token: this.authInfo.token,
        domain: `'|', ('workflow_id', '=', ${this.authInfo.workflowId}), ('workflow_id', '=', False)`,
      })
    },

    openNewActionLink(){
      window.open(this.intranetUrl + "/workflow/action_type/action_task_list#new", "_blank")
    },

    async setNodeInfo(){
      let nodeData = await store.dispatch('getNode', {
        fields: "['id', 'title', 'sequence', 'res_model', 'res_id']",
        domain: "('id', '=', " + this.node.definition.$attrs.backId + ")",
        token: this.authInfo.token,
      })
      if (nodeData){
        this.nodeInfo = nodeData[0]
        this.fillfields()
      }
    },

    updateNode(){
      let title = rules.concatString(`(${this.sequence})\n ${this.title}`)
      this.node.definition.name = title
    },

    fillfields(){
      if (this.nodeInfo){
        this.title = this.nodeInfo.title
        this.sequence = this.nodeInfo.sequence
        if (this.taskList.length > 0){
          let findTask = this.taskList.find(el => el.id === Number(this.nodeInfo.res_id))
          this.selectedTask = findTask ? findTask.title : null
        }
      }
    },

    async putNode(){
      let task = this.taskList.find(el => el.title === this.selectedTask)
      await store.dispatch('putNode', {
        payload: {
          title: this.title,
          sequence: this.sequence,
          res_model: 'intranet.workflow.action.create.task',
          res_id: task.id,
        },
        id: this.node.definition.$attrs.backId,
        token: this.authInfo.token, 
      })
    },
    
    closeDialog(){
      this.$emit("close")
    },
    
    checkFormValidity() {
      return this.rules.find(el => el === false)
    },
  },
};
</script>
<style scoped>
.createBtn{
  width: 80px;
  margin-left: 20px;
  background-color: #0092ff;
}
</style>