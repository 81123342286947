<template>
  <div>
    <crown-button
      :title="$t('Configurar')"
      v-b-tooltip.hover.viewport.d50="{ customClass: 'no-pointer-events' }"
      id="config-button"
      aria-label="Select a type"
      v-on="$listeners"
      @click="openConfigDialog()"
      style="color: white;"
    >
      <i class="fas fa-cog cog-container--button" />
    </crown-button>

    <component
      :is="dialogComp.render"
      :open="dialogComp.props.open"
      :node="node"
      @close="dialogComp.props.open = false"
      @saveChanges="saveChanges"
    >
    </component>
    <dialog-annotation/>
    <alert-dialog
      :open="openAlertDialog"
      :description="messageAlertDialog"
      @close="openAlertDialog = false"
    />
  </div>
</template>
<script>
import CrownButton from '@/components/crown/crownButtons/crownButton'
import dialogAnnotation from '@/components/configDialog/dialogAnnotation'
import dialogTask from '@/components/configDialog/dialogTask'
import dialogSendEmail from '@/components/configDialog/dialogSendEmail'
import dialogSendForm from '@/components/configDialog/dialogSendForm'
import dialogCondition from '@/components/configDialog/dialogCondition'
import dialogStartNode from '@/components/configDialog/dialogStartNode'
import dialogMultGateway from '@/components/configDialog/dialogMultGateway'
import dialogEndNode from '@/components/configDialog/dialogEndNode'
import alertDialog from '@/components/alertDialog/alertDialog'
import rules from '@/mixins/ilios_functions.js'

export default {
  name: 'configButton',

  components: { 
    CrownButton,
    dialogAnnotation,
    dialogTask,
    dialogSendEmail,
    dialogCondition,
    alertDialog,
    dialogStartNode,
  },

  props: {
    node: Object,
    nodeRegistry: Object,
    moddle: Object,
    shape: Object,
  },

  data() {
    return {
      dialogComp: {
        render: null,
        props: {
          open: false,
        }
      },
      openAlertDialog: false,
      messageAlertDialog: '',
    };
  },

  methods: {
    saveChanges(data){
      if (data){
        console.log(data)
      }
      this.$emit("saveXmlInBackEnd")
    },

    openConfigDialog(){
      switch (this.node.type) {
        case "processmaker-modeler-text-annotation":
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogAnnotation
          break;

        case 'processmaker-modeler-exclusive-gateway':
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogMultGateway
          break;

        case 'processmaker-modeler-end-event':
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogEndNode
          break;
          
        case "processmaker-modeler-create-task":
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogTask
          break;

        case "processmaker-modeler-send-email":
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogSendEmail
          break;

        case "processmaker-modeler-send-form":
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogSendForm
          break;

        case "processmaker-modeler-start-event":
          this.dialogComp.props['open'] = true
          this.dialogComp.render = dialogStartNode
          break;

        case "processmaker-modeler-condition":
          let twoConnectedOutputs = rules.twoConnectedOutputs(this.node)
          if (twoConnectedOutputs.result){
            this.dialogComp.props['open'] = true
            this.dialogComp.render = dialogCondition
          }
          else {
            this.messageAlertDialog = twoConnectedOutputs.message
            this.openAlertDialog = true
          }
          break;
      
        default:
          break;
      }
      
    },
  },
};
</script>
